
import { defineComponent, reactive, SetupContext, toRefs } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { requestPassword } from '@/modules/requests';
export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'email',
          rules: 'required|email',
        },
      ]),
    });

    const forgotPassword = () => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;
      requestPassword(getFormData(data.form))
        .then((response) => console.log(response))
        .catch((error) => {
          mapFormErrors(data.form, error?.response?.data?.errors);
          data.form.error = error?.response?.data?.message;
        })
        .finally(() => (data.form.loading = false));
    };

    const navigateToSignIn = () => emit('navigate', 'login');

    return { ...toRefs(data), forgotPassword, navigateToSignIn };
  },
});
