const ValidationRules: any = {
  nullable: {
    test: (): boolean => true,
  },
  required: {
    test: (data: any): boolean =>
      data !== '' && data !== null && data !== undefined,
    message: (): string => `This field is required`,
  },
  is: {
    test: (data: any, value: string): boolean => data === value,
    message: (name: string, value: string): string =>
      `this field ${name} must be ${value}`,
    param: true,
  },
  not: {
    test: (data: any, value: string): boolean => data !== value,
    message: (name: string, value: string): string =>
      `this ${name} must not be ${value}`,
    param: true,
  },
  email: {
    test: (data: any): boolean => {
      if (!data) {
        return true;
      }
      return (
        data &&
        data
          .toString()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
      );
    },
    message: (): string => 'this email is invalid',
  },
  number: {
    test: (data: any): boolean => data && data.toString().match(/^[0-9]+$/),
    message: (): string => 'this field can only contain numbers',
  },
  phone: {
    test: (data: any): boolean =>
      data && data.toString().match(/^(\+|)(234|0)(7|8|9)(0|1)\d{8}$/),
    message: (): string => 'the phone number has to be a valid nigerian number',
  },
  same: {
    test: (name1: string, name2: string, form: any): boolean =>
      form.data[name1]?.value === form.data[name2]?.value,
    message: (name1: string, name2: string): string =>
      `the ${name1} field should the same as the ${name2} field`,
    form: true,
  },
  alpha: {
    test: (data: any): boolean => data && data.toString().match(/^[a-z A-Z]+$/),
    message: (): string => 'this field can only contain letters',
  },
  alphaNum: {
    test: (data: any): boolean =>
      data &&
      data.toString().match(/[a-zA-Z]+/) &&
      data.toString().match(/[0-9]+/),
    message: (): string => 'this field must contain letters and numbers',
  },
  length: {
    test: (data: string, length: number): boolean => {
      if (!data) {
        return false;
      }
      if (typeof length === 'number' && isNaN(length)) {
        throw new Error("rule parameter 'length' has to be a number");
      }
      if (typeof data === 'number') {
        return data === length;
      } else {
        return data.toString().length === length;
      }
    },
    message: (name: string, length: number): string => {
      return `this field ${name} has to be exactly ${length} characters`;
    },
    param: true,
  },
  min: {
    test: (data: any, min: number) => {
      if (!data) {
        return false;
      }
      if (typeof min !== 'number' && isNaN(min)) {
        throw new Error("rule parameter 'min' has to be a number");
      }
      if (typeof data === 'number' || !isNaN(data)) {
        return Number(data) >= min;
      } else {
        return data.toString().length >= min;
      }
    },
    message: (name: string, min: number, data: any) => {
      if (typeof data === 'number' || !isNaN(data)) {
        return `this field has to be at least ${min}`;
      } else {
        return `this field has to contain at least ${min} characters`;
      }
    },
    param: true,
    messageData: true,
  },
  max: {
    test: (data: any, max: number) => {
      if (!data) {
        return false;
      }
      if (typeof max !== 'number' && isNaN(max)) {
        throw new Error("rule parameter 'max' has to be a number");
      }
      if (typeof data === 'number' || !isNaN(data)) {
        return Number(data) <= max;
      } else {
        return data.toString().length <= max;
      }
    },
    message: (name: string, max: number, data: any) => {
      if (typeof data === 'number' || !isNaN(data)) {
        return `this field has to be less than ${max}`;
      } else {
        return `this field has to contain less than ${max} characters`;
      }
    },
    param: true,
    messageData: true,
  },
};

export default ValidationRules;
