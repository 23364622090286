import ValidationRules from '@/helpers/validation-rules';

export default function validation() {
  const validateField = (name: string, data: any, element: any, form: any) => {
    const { value, rules } = data;

    if (!rules) {
      throw new Error('Invalid Validation Rules');
    }
    let errors: any = false;
    let valid = true;
    const errorBag: any = {};

    if (rules.constructor.toString().match(/Function/)) {
      valid = rules(value);
      if (valid === true) {
        errors = false;
        return true;
      }

      // Invalid Returns Error Message
      errors = [valid];
      return false;
    }

    // Split Rules
    rules.split('|').forEach((rule: any) => {
      rule = rule.split(':');
      const ruleName = rule.shift();
      const params = rule.length ? rule.pop().split(',') : [];

      // Check If Rule Exists In Collection
      if (!ValidationRules[ruleName]) {
        return;
      }

      // Get Rule Property
      const RULE = ValidationRules[ruleName];

      let testArguments: any[] = [];
      let messageArguments: any[] = [];

      switch (ruleName) {
        case 'required':
        case 'email':
        case 'privateEmail':
        case 'alpha':
        case 'number':
        case 'alphaNum':
        case 'alphaNumPunct':
        case 'name':
        case 'phone':
        case 'url':
          testArguments = [value];
          messageArguments = [name];
          break;

        case 'is':
        case 'not':
        case 'length':
          testArguments = [value, params[0]];
          messageArguments = [name, params[0]];
          break;

        case 'min':
        case 'max':
          testArguments = [value, params[0]];
          messageArguments = [name, params[0], value];
          break;

        case 'inArray':
        case 'notInArray':
          testArguments = [value, params];
          messageArguments = [name, params];
          break;

        case 'file':
          testArguments = [element];
          messageArguments = [name];
          break;

        case 'same':
          testArguments = [name, params[0], form];
          messageArguments = [name, params[0]];
          break;
        default:
          break;
      }

      // If Nullable Is Set And There is No Value, Validation Is Automatically Passed
      if (
        rules.match(/nullable/) &&
        (!value || (value?.constructor === Array && value.length < 1))
      ) {
        return true;
      }

      // Populate Error Messages When Validation Fails
      if (!RULE.test.apply(null, testArguments)) {
        valid = false;
        errorBag[ruleName] = RULE.message.apply(null, messageArguments);
      }
    });

    if (valid) {
      errors = false;
    } else {
      errors = errorBag;
    }

    data.errors = errors;

    return valid;
  };

  const validateForm = (form: any): boolean => {
    const { data, formElement } = form;
    const length = Object.keys(data).length;
    let validLength = 0;
    for (const name in data) {
      if (
        validateField(
          name,
          data[name],
          formElement && formElement.querySelector(`#${name}`),
          form,
        )
      ) {
        validLength++;
      }
    }
    return length === validLength;
  };

  return {
    validateField,
    validateForm,
  };
}
