<template>
  <div class="grid grid-cols-1 gap-6 sm:mt-16 sm:grid-cols-2 animated reveal">
    <div class="col-span-1 pt-8 sm:pt-16">
      <div class="text-4.5xl font-bold text-invest-primary">
        Forgot Password?
      </div>
    </div>
    <div class="flex justify-end col-span-1">
      <div class="card">
        <form @submit.prevent="forgotPassword">
          <alert variant="error" v-if="form.error">
            {{ form.error }}
          </alert>
          <form-input
            height="h-16"
            type="email"
            name="email"
            :form="form"
            v-model="form.data.email.value"
          >
            Email address
          </form-input>

          <div class="mt-12">
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="form.loading"
            >
              <loader v-if="form.loading" />
              <span v-else>Submit</span>
            </button>
          </div>
        </form>

        <div class="mt-8 text-xs text-center text-invest-dark">
          Already have an account?
          <span
            class="font-semibold cursor-pointer text-invest-purple"
            @click="navigateToSignIn"
          >
            Sign In
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, SetupContext, toRefs } from 'vue';
import resource from '@/modules/resource';
import validation from '@/modules/validation';
import { requestPassword } from '@/modules/requests';
export default defineComponent({
  emits: ['navigate'],
  setup: (_props, { emit }: SetupContext) => {
    const { basicForm, getFormData, mapFormErrors } = resource();
    const { validateForm } = validation();

    const data = reactive({
      form: basicForm([
        {
          name: 'email',
          rules: 'required|email',
        },
      ]),
    });

    const forgotPassword = () => {
      if (!validateForm(data.form)) return false;
      data.form.loading = true;
      requestPassword(getFormData(data.form))
        .then((response) => console.log(response))
        .catch((error) => {
          mapFormErrors(data.form, error?.response?.data?.errors);
          data.form.error = error?.response?.data?.message;
        })
        .finally(() => (data.form.loading = false));
    };

    const navigateToSignIn = () => emit('navigate', 'login');

    return { ...toRefs(data), forgotPassword, navigateToSignIn };
  },
});
</script>
