/* eslint-disable @typescript-eslint/no-unused-vars */
import { Resource, BasicForm } from '@/types/Resource';

export default function dataResource() {
  const resources = (value: any, config = {}): Resource => {
    return {
      data: value,
      error: null,
      loading: false,
      ...config,
    };
  };
  const getFormData = (form: any) => {
    const data: any = {};
    for (const key in form?.data) {
      data[key] = form?.data[key].value;
    }
    return data;
  };

  const mapFormErrors = (form: any, errors: any = {}) => {
    for (const key in errors) {
      if (form.data[key]) {
        form.data[key].errors = errors[key];
      }
    }
  };

  const getFormError = (form: any) => {
    if (!form.error) {
      return null;
    }

    if (form.error?.toString().match(/Error: Network Error/i)) {
      return 'Please check your internet connection.';
    }

    if (form.error?.response?.data?.message) {
      return form.error.response.data.message;
    }

    const errors = form.error?.response?.data?.errors;
    if (errors && Object.keys(errors).length) {
      return 'You are missing something in your data. Please check the form.';
    }

    if (form.error) {
      return form.error.message;
    }
    return 'We seem to be experiencing server issues. Please try again later.';
  };

  const basicForm = (keys: any = [], extra: any = {}): BasicForm => {
    const form: BasicForm = {
      data: {},
      loading: false,
      error: false,
      ...extra,
    };

    keys.forEach((key: any) => {
      let name = key;
      const value = '';
      const errors = false;
      const rules = 'required';

      switch (key?.constructor) {
        case String:
          form.data[name] = {
            value,
            errors,
            rules,
          };
          break;
        case Object:
          name = key?.name !== undefined ? key?.name : name;
          form.data[name] = {
            ...key,
            value: key.value !== undefined ? key.value : value,
            errors: key.errors !== undefined ? key.errors : errors,
            rules: key.rules !== undefined ? key.rules : rules,
          };
          break;
        default:
        // statements_def
      }
    });

    form._base_state = JSON.parse(JSON.stringify(form));

    return form;
  };

  return {
    resources,
    basicForm,
    getFormData,
    getFormError,
    mapFormErrors,
  };
}
